var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = "/monitoring-tunnel";
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"864b85b86ff8303ed7843f146583523f7bfdb544"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import * as Sentry from "@sentry/nextjs"
import posthog from "posthog-js"

import { env } from "@/env.mjs"

if (
  typeof window !== "undefined" &&
  env.NEXT_PUBLIC_NODE_ENV !== "development"
) {
  posthog.init(env.NEXT_PUBLIC_POSTHOG_KEY, {
    api_host: `${env.NEXT_PUBLIC_APP_URL}/ingest`,
    ui_host: "https://eu.posthog.com",
    capture_pageview: false, // Disable automatic pageview capture, as we capture manually
    persistence: "localStorage+cookie",
    loaded: (posthog) => {
      if (process.env.NODE_ENV === "development") posthog.debug() // comment the following to disable console logs
    },
  })
}

Sentry.init({
  dsn: env.NEXT_PUBLIC_SENTRY_DSN,
  // Replay may only be enabled for the client-side
  integrations: [
    // new Sentry.Replay(), // Quickly depleted, active once we have a paid plan
    new posthog.SentryIntegration(
      posthog,
      "wasii",
      Number.parseInt(env.NEXT_PUBLIC_SENTRY_PROJECT_ID)
    ),
  ],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate:
    env.NEXT_PUBLIC_NODE_ENV === "development"
      ? 0
      : env.NEXT_PUBLIC_NODE_ENV === "test"
        ? 0.1
        : 0.05,

  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,

  // ...

  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
})
